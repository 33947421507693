body {
  font-family: Roboto, Helvetica Neue, sans-serif;
  height: 100%;
}

a, a:hover {
  text-decoration: none;
}


// Material styles overwritten
.mat-button-toggle-group > .mat-button-toggle > button.mat-button-toggle-button > div.mat-button-toggle-label-content {
  line-height: 28px;
}

.float-right {
  float: right;
}
